<template>
  <div>
    <my-nav-bar
      title="核对记录"
      left-text="返回"
      left-arrow
    />
    <van-sticky offset-top="1.22667rem">
      <van-search v-model="listQuery.search_key" placeholder="商品名称" clearable @search="onSearch" />
    </van-sticky>
    <div class="app-main-container list">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list ref="list" v-model="loading" :finished="finished" @load="getList">
        <div v-for="(transOrder, index) in list" :key="index" class="accept-item" @click="toDetailView(transOrder.id)">
          <van-cell title="核对人" :value="transOrder.accepter.name" />
          <van-cell title="核对时间" :value="transOrder.accept_datetime" />
          <van-cell :title="`${transOrder.from_store.name}→${transOrder.to_store.name}`" :value="transOrder.created_at" />
          <van-cell :title="`${transOrder.kind}种商品`" :value="`共￥${transOrder.cost_amount}`" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getTransCheckRecords } from '@/api/trans_check'
export default {
  name: 'TransCheckRecord',
  components: { myNavBar },
  data() {
    return {
      list: [],
      listQuery: {
        page: 0,
        search_key: ''
      },
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path !== '/trans-check-record-detail') {
      this.$store.commit('rmKeepAlive', this.$options.name)
    }
    next()
  },
  methods: {
    getList() {
      this.listQuery.page++
      getTransCheckRecords(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < 10
        this.showEmpty = this.list.length === 0
      })
    },
    toDetailView(transOrderId = null) {
      this.$router.push({
        path: '/trans-check-record-detail',
        query: {
          trans_order_id: transOrderId
        }
      })
    },
    onSearch() {
      this.listQuery.page = 0
      this.finished = false
      this.showEmpty = false
      this.list = []
      this.$refs.list.check()
    }
  }
}
</script>

<style lang="scss" scoped>
  .accept-item {
    margin-bottom: 10px;
  }
  .list {
    padding: 10px 10px 0 10px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
</style>
